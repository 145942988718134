import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Flex, Input, Modal, Tooltip } from 'antd'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IMobileComponent } from '../../models/ContainerProps'
import '../../styles/style.css'
import { localize } from '../../utils/Language'
import NewTabLink from '../global/NewTabLink'
interface CreateNewAppProps {
    onCreateNewApp: (appName: string, hasPersistency: boolean) => void,
    isMobile: boolean
}

class CreateNewApp extends Component<
    CreateNewAppProps,
    { appName: string; hasPersistency: boolean; isModalOpen: boolean }
> {
    constructor(props: CreateNewAppProps) {
        super(props)
        this.state = {
            hasPersistency: false,
            appName: '',
            isModalOpen: false,
        }
    }

    showModal = () => {
        this.setState({ isModalOpen: true })
    }

    handleCancel = () => {
        this.setState({ isModalOpen: false })
    }

    onCreateNewAppClicked() {
        this.props.onCreateNewApp(
            this.state.appName,
            this.state.hasPersistency
        )
    }

    render() {
        const self = this
        const collapseContent = (
            <>
                <Flex
                    style={{
                        flexDirection: 'column',
                        gap: '1em',
                        marginBottom: '1em',
                    }}
                >
                    {self.props.isMobile ? (
                        <Fragment>
                            <Input
                                placeholder={localize(
                                    'create_new_app.placeholder',
                                    'my-amazing-app'
                                )}
                                onChange={(e) =>
                                    self.setState({
                                        appName: e.target.value,
                                    })
                                }
                            />
                            <Button
                                style={{ marginTop: 8 }}
                                block
                                onClick={() => {
                                    this.props.onCreateNewApp(
                                        this.state.appName,
                                        this.state.hasPersistency
                                    )
                                }}
                                type="primary"
                            >
                                {localize(
                                    'create_new_app.button',
                                    'Create New App'
                                )}
                            </Button>
                        </Fragment>
                    ) : (
                        <Input.Search
                            placeholder={localize(
                                'create_new_app.placeholder',
                                'my-amazing-app'
                            )}
                            enterButton={'Create New App'}
                            onSearch={() => this.onCreateNewAppClicked()}
                            onChange={(e) => this.setState({appName: e.target.value})}
                        />
                    )}
                    <div>
                        <Checkbox
                            onChange={(e: any) =>
                                self.setState({
                                    hasPersistency: !!e.target.checked,
                                })
                            }
                        >
                            {localize(
                                'create_new_app.has_persistent_data',
                                'Has Persistent Data'
                            )}{' '}
                        </Checkbox>
                        <Tooltip
                            title={localize(
                                'create_new_app.has_persistent_data_tooltip',
                                'Mostly used for databases, see docs for details.'
                            )}
                        >
                            <NewTabLink url="https://SudoShip.com/docs/persistent-apps.html">
                                <span>
                                    <QuestionCircleFilled />
                                </span>
                            </NewTabLink>
                        </Tooltip>
                    </div>
                </Flex>

                <Flex style={{ justifyContent: 'center', marginTop: '0.5em' }}>
                    <Flex style={{ alignItems: 'center' }}>
                        {localize(
                            'create_new_app.or_select_from',
                            'Or Select From'
                        )}
                        :&nbsp;
                        <Link to="/apps/oneclick/">
                            <Button type="default">
                                {localize(
                                    'create_new_app.one_click_apps',
                                    'One-Click Apps/Databases'
                                )}
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
            </>
        )

        return (
            <>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={this.showModal}
                    className="custom-create-app-button"
                >
                    Create New App
                </Button>
                <Modal
                    title="Create New App"
                    open={this.state.isModalOpen}
                    onCancel={this.handleCancel}
                    footer={[]}
                >
                    {collapseContent}
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect<IMobileComponent, any, any>(
    mapStateToProps,
    undefined
)(CreateNewApp)
