import { Button, Input, message, Row } from 'antd'
import Toaster from '../../utils/Toaster'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'

const styles = {
    container: {
        padding: '20px',
        borderRadius: '15px',
        maxWidth: '400px',
        margin: 'auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    input: {
        borderRadius: '25px',
        borderColor: '#6a7f9b50',
        height: '45px',
        marginBottom: '20px',
        paddingLeft: '15px',
    },
    button: {
        borderRadius: '25px',
        height: '45px',
        width: '100%',
    },
    row: {
        justifyContent: 'center',
    },
    '@media (max-width: 600px)': {
        container: {
            padding: '10px',
            maxWidth: '90%',
        },
        input: {
            height: '40px',
        },
        button: {
            height: '40px',
        },
    },
}

interface ChangePassProps {
    isMobile: boolean
}

interface ChangePassState {
    isLoading: boolean
    old: string
    new1: string
    new2: string
    isMobile: boolean
}

class ChangePass extends ApiComponent<ChangePassProps, ChangePassState> {
    constructor(props: ChangePassProps) {
        super(props)
        this.state = {
            isLoading: false,
            old: '',
            new1: '',
            new2: '',
            isMobile: window.innerWidth <= 600,
        }
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 600 })
    }

    onChangePasswordClicked = () => {
        const { old, new1, new2 } = this.state
        if (!new1) {
            message.error('New password cannot be empty')
            return
        }

        if (new1 !== new2) {
            message.error('New passwords do not match')
            return
        }

        this.setState({ isLoading: true })

        this.apiManager
            .changePass(old, new1)
            .then(() => {
                message.success('Password changed successfully!')
                return this.apiManager.getAuthToken(new1)
            })
            .catch(Toaster.createCatcher())
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        const { isLoading, isMobile, old, new1, new2 } = this.state

        if (isLoading) {
            return <CenteredSpinner />
        }

        const containerStyle = {
            ...styles.container,
            ...(isMobile && styles['@media (max-width: 600px)'].container),
        }
        const inputStyle = {
            ...styles.input,
            ...(isMobile && styles['@media (max-width: 600px)'].input),
        }
        const buttonStyle = {
            ...styles.button,
            ...(isMobile && styles['@media (max-width: 600px)'].button),
        }

        return (
            <div style={containerStyle}>
                <div style={{ marginBottom: '10px' }}>Old Password</div>
                <Input.Password
                    style={inputStyle}
                    value={old}
                    onChange={(e) => this.setState({ old: e.target.value })}
                />
                <div style={{ marginBottom: '10px' }}>New Password</div>
                <Input.Password
                    maxLength={30}
                    style={inputStyle}
                    value={new1}
                    onChange={(e) => this.setState({ new1: e.target.value })}
                />
                <div style={{ marginBottom: '10px' }}>Confirm New Password</div>
                <Input.Password
                    maxLength={30}
                    style={inputStyle}
                    value={new2}
                    onChange={(e) => this.setState({ new2: e.target.value })}
                />
                <Row style={styles.row}>
                    <Button
                        style={buttonStyle}
                        onClick={this.onChangePasswordClicked}
                        type="primary"
                    >
                        Change Password
                    </Button>
                </Row>
            </div>
        )
    }
}

export default ChangePass
