import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
    Alert,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Tabs,
    Tag,
    Tooltip,
    Typography,
    message,
} from 'antd'
import { connect } from 'react-redux'
import Toaster from '../../utils/Toaster'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'

const { Title } = Typography
const { TabPane } = Tabs

interface Props {
    defaultRegistryId?: string
    isMobile: boolean
}

interface State {
    isLoading: boolean
    apiData?: any
    isModalVisible: boolean
    currentPage: number
    pageSize: number
    searchQuery: string
}

class CurrentNodes extends ApiComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            apiData: undefined,
            isLoading: true,
            isModalVisible: false,
            currentPage: 1,
            pageSize: 5, // Define the number of nodes per page
            searchQuery: '', // Adding state for search filter
        }
    }

    fetchData() {
        const self = this
        self.setState({ apiData: undefined, isLoading: true })
        self.apiManager
            .getAllNodes()
            .then(function (data) {
                self.setState({ apiData: data })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    addNode = (nodeToAdd: any) => {
        const self = this
        self.setState({ apiData: undefined, isLoading: true })
        self.apiManager
            .addDockerNode(
                nodeToAdd.nodeType,
                nodeToAdd.privateKey,
                nodeToAdd.remoteNodeIpAddress,
                nodeToAdd.sshPort,
                nodeToAdd.sshUser,
                nodeToAdd.captainIpAddress
            )
            .then(function () {
                message.success('Node added successfully!')
                self.setState({ isModalVisible: false })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.fetchData()
            })
    }

    componentDidMount() {
        this.fetchData()
    }

    createNodes() {
        const { currentPage, pageSize, searchQuery } = this.state
        let nodes = this.state.apiData?.nodes || []

        // Search filter
        if (searchQuery) {
            nodes = nodes.filter(
                (node: any) =>
                    node.nodeId
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    node.hostname
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    node.ip.toLowerCase().includes(searchQuery.toLowerCase())
            )
        }

        const paginatedNodes = nodes.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        )

        return paginatedNodes.map((node: any) => (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style={{ width: '80%' }}
                    key={node.nodeId}
                    title={
                        <>
                            <b>Node ID:</b> {node.nodeId}{' '}
                            {node.isLeader && <Tag color="gold">Leader</Tag>}
                        </>
                    }
                    // style={{
                    //     marginBottom: 12,
                    //     borderRadius: 8,
                    //     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    //     padding: 16,
                    // }}
                    actions={[
                        <Tooltip title="Node Info" key="info">
                            <InfoCircleOutlined />
                        </Tooltip>,
                    ]}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="General" key="1">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="Type">
                                            {node.isLeader
                                                ? 'Leader (Main Node)'
                                                : node.type}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="IP">
                                            {node.ip}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Hostname">
                                            {node.hostname}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="OS Architecture">
                                            {node.osArchitecture}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Docker Version">
                                            {node.dockerVersion}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Resources" key="2">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="RAM">
                                            {(
                                                node.memoryBytes / 1073741824
                                            ).toFixed(2)}{' '}
                                            GB
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="CPU">
                                            {(
                                                node.nanoCpu / 1000000000
                                            ).toFixed(0)}{' '}
                                            cores
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Status" key="3">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="State">
                                            <Tag
                                                color={
                                                    node.state === 'ready'
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            >
                                                {node.state}
                                            </Tag>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Status">
                                            {node.status}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Additional Details" key="4">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="Uptime">
                                            {node.uptime || 'Unavailable'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Last Updated">
                                            {node.lastUpdated || 'Unavailable'}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        ))
    }

    handlePageChange = (page: number) => {
        this.setState({ currentPage: page })
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchQuery: e.target.value })
    }

    showModal = () => {
        this.setState({ isModalVisible: true })
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false })
    }

    render() {
        if (this.state.isLoading) {
            return <CenteredSpinner />
        }

        if (!this.state.apiData) {
            return <ErrorRetry />
        }

        const nodes = this.state.apiData?.nodes || []
        const totalNodes = nodes.length

        return (
            <div>
                {this.props.defaultRegistryId ? (
                    <>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={this.showModal}
                            style={{
                                marginBottom: 12,
                                borderRadius: 6,
                                padding: '0 12px',
                                height: 32,
                                // fontSize: 14,
                            }}
                        >
                            Add Node
                        </Button>
                        <Modal
                            title="Add Node"
                            visible={this.state.isModalVisible}
                            onCancel={this.handleCancel}
                            footer={undefined}
                        >
                            <Form layout="vertical" onFinish={this.addNode}>
                                <Form.Item
                                    name="nodeType"
                                    label="Node Type"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input the node type!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="privateKey"
                                    label="Private Key"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input the private key!',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                {/* Add other form items as necessary */}
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            borderRadius: 6,
                                            width: '100%',
                                            height: 32,
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                ) : (
                    <Alert
                        type="warning"
                        showIcon
                        message="Cannot add more nodes as no default push registry is set. To add more nodes and create a cluster, you first need to add a docker registry and set it as the default push registry."
                    />
                )}
                <Divider type="horizontal">
                    <Title level={4} style={{ fontSize: 17 }}>
                        Current Cluster Nodes
                    </Title>
                </Divider>
                <Row justify="center">
                    <Col lg={18} xs={24}>
                        <Input
                            placeholder="Search Nodes by ID, Hostname or IP"
                            onChange={this.handleSearch}
                            style={{
                                marginBottom: 20,
                                borderRadius: 6,
                                padding: '8px 12px',
                                // fontSize: 14,
                            }}
                        />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col lg={18} xs={24}>
                        {this.createNodes()}
                        <Pagination
                            current={this.state.currentPage}
                            total={totalNodes}
                            pageSize={this.state.pageSize}
                            onChange={this.handlePageChange}
                            style={{ textAlign: 'center', marginTop: 20 }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        defaultRegistryId: state.registryReducer.defaultRegistryId,
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect(mapStateToProps)(CurrentNodes)
