import { Avatar, Button, Dropdown, Flex, Layout, Menu, MenuProps } from 'antd'
import { BarsOutlined, GiftTwoTone, UserOutlined } from '@ant-design/icons'
import React, { Fragment, RefObject } from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'
import ApiManager from '../api/ApiManager'
import { IVersionInfo } from '../models/IVersionInfo'
import * as GlobalActions from '../redux/actions/GlobalActions'
import { localize } from '../utils/Language'
import StorageHelper from '../utils/StorageHelper'
import Dashboard from './Dashboard'
import LoggedInCatchAll from './LoggedInCatchAll'
import Apps from './apps/Apps'
import AppDetails from './apps/appDetails/AppDetails'
import OneClickAppSelector from './apps/oneclick/selector/OneClickAppSelector'
import OneClickAppConfigPage from './apps/oneclick/variables/OneClickAppConfigPage'
import ApiComponent from './global/ApiComponent'
import ClickableLink from './global/ClickableLink'
import Monitoring from './monitoring/Monitoring'
import Cluster from './nodes/Cluster'
import Settings from './settings/Settings'

const { Header, Content, Sider } = Layout

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

class PageRoot extends ApiComponent<
    RootPageInterface,
    {
        versionInfo: IVersionInfo | undefined
        collapsed: boolean
        isScrolled: boolean // novo estado
    }
> {
    private mainContainer: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props)
        this.mainContainer = React.createRef()
        this.state = {
            versionInfo: undefined,
            collapsed: false,
            isScrolled: false, // inicialização do novo estado
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    handleScroll = () => {
        const isScrolled = window.pageYOffset > 0
        this.setState({ isScrolled })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
        window.removeEventListener('scroll', this.handleScroll) // Remover listener de rolagem
    }

    componentDidUpdate(prevProps: any) {
        if (
            this.props.location.pathname !== prevProps.location.pathname &&
            this.props.isMobile
        ) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        const self = this
        this.updateDimensions()

        window.addEventListener('resize', this.updateDimensions)
        window.addEventListener('scroll', this.handleScroll) // Adicionar evento de rolagem

        if (!ApiManager.isLoggedIn()) {
            this.goToLogin()
        } else {
            this.apiManager
                .getVersionInfo()
                .then(function (data) {
                    self.setState({ versionInfo: data })
                })
                .catch((err) => {
                    // ignore error
                })
            this.setState({
                collapsed:
                    StorageHelper.getSiderCollapsedStateFromLocalStorage(),
            })
        }
    }

    goToLogin() {
        this.props.history.push('/login')
    }

    createUpdateAvailableIfNeeded() {
        const self = this

        if (!self.state.versionInfo || !self.state.versionInfo.canUpdate) {
            return undefined
        }

        return (
            <Fragment>
                <ClickableLink
                    onLinkClicked={() => self.props.history.push('/settings')}
                >
                    <GiftTwoTone
                        style={{
                            marginLeft: 50,
                        }}
                    />
                    <GiftTwoTone
                        style={{
                            marginRight: 10,
                            marginLeft: 3,
                        }}
                    />
                    Update Available!
                    <GiftTwoTone
                        style={{
                            marginLeft: 10,
                        }}
                    />
                    <GiftTwoTone
                        style={{
                            marginLeft: 3,
                        }}
                    />
                </ClickableLink>
            </Fragment>
        )
    }

    toggleSider = () => {
        StorageHelper.setSiderCollapsedStateInLocalStorage(
            !this.state.collapsed
        )
        this.setState({ collapsed: !this.state.collapsed })
    }

    render() {
        const self = this

        const MENU_ITEMS: MenuProps['items'] = [
            {
                key: 'dashboard',
                label: localize('menu_item.dashboard', 'Dashboard'),
            },
            {
                key: 'apps',
                label: localize('menu_item.app', 'App'),
            },
            {
                key: 'monitoring',
                label: localize('menu_item.monitoring', 'Monitoring'),
            },
            {
                key: 'cluster',
                label: localize('menu_item.cluster', 'Cluster'),
            },
            {
                key: 'settings',
                label: localize('menu_item.settings', 'Settings'),
            },
        ]

        const items: MenuProps['items'] = [
            {
                key: 'github',
                label: (
                    <a
                        onClick={() => {
                            window.open(
                                'https://github.com/SudoShip/SudoShip',
                                '_blank'
                            )
                        }}
                    >
                        GitHub
                    </a>
                ),
            },
            {
                key: 'docs',
                label: (
                    <a
                        onClick={() => {
                            window.open('https://SudoShip.com', '_blank')
                        }}
                    >
                        Docs
                    </a>
                ),
            },
            {
                key: 'logout',
                label: (
                    <Button
                        type="text"
                        className="custom-logout-button"
                        onClick={() => {
                            self.apiManager.setAuthToken('')
                            self.goToLogin()
                        }}
                    >
                        Logout
                    </Button>
                ),
            },
        ]

        const headerClassName = this.state.isScrolled ? 'fixed-header' : ''

        return (
            <Layout className="full-screen">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1em',
                        marginBottom: '1em',
                    }}
                >
                    <Header
                        className={headerClassName}
                        style={{
                            padding: `0 ${this.props.isMobile ? 15 : 50}px`,
                            backgroundColor: 'rgb(4, 4, 4)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}
                    >
                        {this.props.isMobile && (
                            <Button
                                ghost
                                icon={<BarsOutlined />}
                                onClick={(e) => e.preventDefault()}
                            />
                        )}

                        <Flex style={{ width: '100%' }}>
                            {(this.props.isMobile &&
                                self.createUpdateAvailableIfNeeded()) || (
                                <Flex
                                    style={{
                                        width: this.props.isMobile
                                            ? '100%'
                                            : '',
                                    }}
                                    align="center"
                                    justify="center"
                                    gap={10}
                                >
                                    <img
                                        alt="logo"
                                        src="/full-logo.png"
                                        style={{
                                            height: 45,
                                        }}
                                    />

                                    {self.createUpdateAvailableIfNeeded()}
                                </Flex>
                            )}

                            {!this.props.isMobile && (
                                <Menu
                                    mode="horizontal"
                                    selectedKeys={[
                                        this.props.location.pathname.substring(
                                            1
                                        ),
                                    ]}
                                    theme="dark"
                                    defaultSelectedKeys={['dashboard']}
                                    style={{
                                        width: '70%',
                                        borderRight: 0,
                                        backgroundColor: 'rgb(4, 4, 4)',
                                    }}
                                    items={MENU_ITEMS}
                                    onClick={(e) => {
                                        this.props.history.push(`/${e.key}`)
                                    }}
                                    className="custom-menu"
                                />
                            )}
                        </Flex>

                        <Flex
                            style={{
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: 25,
                            }}
                        >
                            <Dropdown
                                menu={{ items }}
                                trigger={['click']}
                                overlayClassName="custom-dropdown-menu"
                            >
                                <Avatar
                                    style={{
                                        backgroundColor: '#01aef5',
                                        cursor: 'pointer',
                                        width: '35px', // Tamanho do círculo (largura)
                                        height: '35px', // Tamanho do círculo (altura)
                                    }}
                                    icon={
                                        <UserOutlined
                                            style={{
                                                color: '#fff',
                                                fontSize: '20px', // Tamanho do ícone
                                            }}
                                        />
                                    }
                                />
                            </Dropdown>
                        </Flex>
                    </Header>
                </div>

                <Content>
                    <div
                        key={self.props.rootElementKey}
                        ref={self.mainContainer}
                        style={{
                            paddingTop: 12,
                            paddingBottom: 36,
                            height: '100%',
                            overflowY: 'scroll',
                            marginRight: self.state.collapsed
                                ? 0
                                : self.props.isMobile
                                ? -200
                                : 0,
                            transition: 'margin-right 0.3s ease',
                        }}
                        id="main-content-layout"
                    >
                        <Switch>
                            <Route path="/dashboard/" component={Dashboard} />
                            <Route
                                path="/apps/details/:appName"
                                render={(props) => (
                                    <AppDetails
                                        {...props}
                                        mainContainer={self.mainContainer}
                                    />
                                )}
                            />
                            <Route
                                path="/apps/oneclick/:appName"
                                component={OneClickAppConfigPage}
                            />
                            <Route
                                path="/apps/oneclick"
                                component={OneClickAppSelector}
                            />
                            <Route path="/apps/" component={Apps} />
                            <Route path="/monitoring/" component={Monitoring} />
                            <Route path="/cluster/" component={Cluster} />
                            <Route path="/settings/" component={Settings} />
                            <Route component={LoggedInCatchAll} />
                        </Switch>
                    </div>
                </Content>
            </Layout>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitSizeChanged: () => dispatch(GlobalActions.emitSizeChanged()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageRoot)
