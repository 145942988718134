import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Modal, Tooltip } from 'antd'
import { Component } from 'react'
import {
    IRegistryApi,
    IRegistryInfo,
    IRegistryTypes,
} from '../../models/IRegistryInfo'
import Utils from '../../utils/Utils'
import PasswordField from '../global/PasswordField'

const ADDING_LOCAL = 'ADDING_LOCAL'
const ADDING_REMOTE = 'ADDING_REMOTE'

export default class DockerRegistryAdd extends Component<
    {
        apiData: IRegistryApi
        addDockerRegistry: (dockerRegistry: IRegistryInfo) => void
        isMobile: boolean
    },
    {
        modalShowing: 'ADDING_LOCAL' | 'ADDING_REMOTE' | undefined
        remoteRegistryToAdd: IRegistryInfo
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            modalShowing: undefined,
            remoteRegistryToAdd: this.getPlainRegistryInfo(),
        }
    }

    getPlainRegistryInfo(): IRegistryInfo {
        return {
            id: '',
            registryUser: '',
            registryPassword: '',
            registryDomain: '',
            registryImagePrefix: '',
            registryType: IRegistryTypes.REMOTE_REG,
        }
    }

    render() {
        const self = this

        const hasSelfHostedRegistry =
            this.props.apiData.registries
                .map((reg) => reg.registryType)
                .indexOf(IRegistryTypes.LOCAL_REG) >= 0


        const inputStyle = {
            borderRadius: '8px', // Bordas arredondadas para os campos de input
        }

        const sectionStyle = {
            marginBottom: '20px', // Espaçamento entre as seções
        }

        const descriptionStyle = {
            marginTop: '20px', // Espaçamento acima da descrição
        }

        return (
            <div>
                <Modal
                    title="Self-Hosted Registry"
                    okText="Enable Self-Hosted Registry"
                    onCancel={() => self.setState({ modalShowing: undefined })}
                    onOk={() => {
                        self.setState({ modalShowing: undefined })
                        self.props.addDockerRegistry({
                            registryType: IRegistryTypes.LOCAL_REG,
                        } as IRegistryInfo)
                    }}
                    open={self.state.modalShowing === ADDING_LOCAL}
                >
                    <p>
                        You can read more about this type of registry on the
                        page behind this modal, specifically under "More Info"
                        section. Do you want to proceed and enable self-hosted
                        Docker Registry?
                    </p>
                </Modal>

                <Modal
                    title="Remote Registry"
                    okText="Add Remote Registry"
                    onCancel={() => self.setState({ modalShowing: undefined })}
                    onOk={() => {
                        self.setState({ modalShowing: undefined })
                        self.props.addDockerRegistry(
                            self.state.remoteRegistryToAdd
                        )
                    }}
                    open={self.state.modalShowing === ADDING_REMOTE}
                >
                    <div >
                        <Flex style={{flexDirection: "column"}} gap="middle">
                        <Input
                                addonBefore="Username"
                                placeholder="username | email@gmail.com"
                                type="email"
                                value={
                                    self.state.remoteRegistryToAdd.registryUser
                                }
                                onChange={(e) => {
                                    const newData = Utils.copyObject(
                                        self.state.remoteRegistryToAdd
                                    )
                                    newData.registryUser = e.target.value.trim()
                                    self.setState({
                                        remoteRegistryToAdd: newData,
                                    })
                                }}
                            />
                            <PasswordField
                                addonBefore="Password"
                                placeholder="mypassword"
                                defaultValue={
                                    self.state.remoteRegistryToAdd
                                        .registryPassword
                                }
                                onChange={(e) => {
                                    const newData = Utils.copyObject(
                                        self.state.remoteRegistryToAdd
                                    )
                                    newData.registryPassword = e.target.value
                                    self.setState({
                                        remoteRegistryToAdd: newData,
                                    })
                                }}
                            />
                            <Input
                                addonBefore="Domain"
                                placeholder="registry-1.docker.io"
                                type="text"
                                value={
                                    self.state.remoteRegistryToAdd
                                        .registryDomain
                                }
                                onChange={(e) => {
                                    const newData = Utils.copyObject(
                                        self.state.remoteRegistryToAdd
                                    )
                                    newData.registryDomain =
                                        e.target.value.trim()
                                    self.setState({
                                        remoteRegistryToAdd: newData,
                                    })
                                }}
                            />
                            <Input
                                addonBefore="Image Prefix"
                                placeholder="username"
                                addonAfter={
                                    <Tooltip title="Your images will be tagged as RegistryDomain/ImagePrefix/ImageName. For most providers, Image Prefix is exactly your username, unless the field DOMAIN is specific to you, in that case, this prefix is empty.">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                type="text"
                                value={
                                    self.state.remoteRegistryToAdd
                                        .registryImagePrefix
                                }
                                onChange={(e) => {
                                    const newData = Utils.copyObject(
                                        self.state.remoteRegistryToAdd
                                    )
                                    newData.registryImagePrefix =
                                        e.target.value.trim()
                                    self.setState({
                                        remoteRegistryToAdd: newData,
                                    })
                                }}
                            />
                        </Flex>
                        <p style={descriptionStyle}>
                            You can read more about this type of registry on the
                            page behind this modal, specifically under "More
                            Info" section.
                        </p>
                    </div>
                </Modal>

                <Flex gap="middle" style={{flexDirection: "row-reverse"}}>
                    <div
                        className={
                            hasSelfHostedRegistry ? 'hide-on-demand' : ''
                        }
                    >
                        <Button
                            type='primary'
                            block={this.props.isMobile}
                            onClick={() =>
                                self.setState({
                                    modalShowing: ADDING_LOCAL,
                                })
                            }
                        >
                            Add Self-Hosted Registry
                        </Button>
                    </div>
                    <Button
                        type='primary'
                        block={this.props.isMobile}
                        onClick={() =>
                            self.setState({
                                modalShowing: ADDING_REMOTE,
                                remoteRegistryToAdd:
                                    self.getPlainRegistryInfo(),
                            })
                        }
                    >
                        Add Remote Registry
                    </Button>
                </Flex>
            </div>
        )
    }
}
