import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Typography } from 'antd'
import { IHashMapGeneric } from '../../../models/IHashMapGeneric'
import Utils from '../../../utils/Utils'
import ApiComponent from '../../global/ApiComponent'
import ErrorRetry from '../../global/ErrorRetry'
import onDeleteAppClicked from '../DeleteAppConfirm'
import { AppDetailsTabProps, SingleAppApiData } from './AppDetails'

export default class Settings extends ApiComponent<
    AppDetailsTabProps,
    {
        apiData: SingleAppApiData | undefined
        renderCounterForAffixBug: number
        // selectedApp: string
    }
> {
    private reRenderTriggered = false
    private confirmedAppNameToDelete: string = ''
    private volumesToDelete: IHashMapGeneric<boolean> = {}

    constructor(props: AppDetailsTabProps) {
        super(props)

        this.state = {
            renderCounterForAffixBug: 0,
            apiData: props.apiData,
        }

        this.reFetchData = this.reFetchData.bind(this)
    }

    render() {
        if (!this.reRenderTriggered) {
            // crazy hack to make sure the Affix is showing (delete and save & update)
            this.reRenderTriggered = true
            setTimeout(() => {
                this.setState({ renderCounterForAffixBug: 1 })
            }, 50)
        }

        if (!this.props.apiData) {
            return <ErrorRetry />
        }

        return (
            <>
                <Typography
                    style={{
                        fontSize: '2em',
                        fontWeight: '600',
                        marginBottom: '1em',
                    }}
                >
                    Settings
                </Typography>
                <Flex
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '1.5em',
                            fontWeight: '600',
                            marginBottom: '0.5em',
                        }}
                    >
                        Danger Zone
                    </Typography>

                    <Card>
                        <Flex
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>
                                <Typography style={{ fontWeight: 700 }}>
                                    Delete App
                                </Typography>
                                <Typography>
                                    All content in your app will be deleted and
                                    this action cannot be undone.
                                </Typography>
                            </Typography>

                            <Button
                                danger
                                size="large"
                                onClick={() => {
                                    console.log(this.state)
                                    onDeleteAppClicked(
                                        [
                                            Utils.copyObject(
                                                this.state.apiData!.appDefinition
                                            ),
                                        ],
                                        this.apiManager,
                                        (success) => {
                                            window.location.reload()
                                        }
                                    )
                                }}
                            >
                                {this.props.isMobile ? (
                                    <DeleteOutlined />
                                ) : (
                                    'Delete App'
                                )}
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            </>
        )
    }

    reFetchData() {
        // Agora estamos chamando o método diretamente sem 'self'
        this.props.reFetchData()
    }
}
