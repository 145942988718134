import { Card, Checkbox, Col, Input, Row, Typography } from 'antd'
import { Component } from 'react'
import Utils from '../../utils/Utils'
import PasswordField from '../global/PasswordField'

const { Title, Paragraph, Text } = Typography

interface NetDataSettingsFormProps {
    netDataInfo: any
    updateModel: (netDataInfo: any) => void
}

export default class NetDataSettingsForm extends Component<NetDataSettingsFormProps> {
    changeModel(
        parentField: string,
        childField: string,
        value: string | boolean
    ) {
        const netDataInfo = Utils.copyObject(this.props.netDataInfo)
        const netDataInfoData = netDataInfo.data
        if (!netDataInfoData[parentField]) {
            netDataInfoData[parentField] = {}
        }
        netDataInfoData[parentField][childField] = value
        this.props.updateModel(netDataInfo)
    }

    render() {
        const netDataInfo = this.props.netDataInfo
        return (
            <div>
                <Title level={3} style={{ fontSize: '18px' }}>
                    Notification Settings
                </Title>
                <Paragraph>
                    NetData offers multiple ways for you to receive
                    notifications if something is going wrong with your server
                    resource usage.
                    <i>All notification options are completely OPTIONAL.</i>
                </Paragraph>
                <hr />
                <br />

                <Card title="Email (SMTP)">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Text>Recipient Email</Text>
                            <Input
                                type="text"
                                placeholder="alerts.receiver@example.com"
                                value={netDataInfo.data.smtp.to}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'to',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>Server Tag</Text>
                            <Input
                                type="text"
                                placeholder="my-aws-server-01-anything"
                                value={netDataInfo.data.smtp.hostname}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'hostname',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>SMTP Server</Text>
                            <Input
                                type="text"
                                placeholder="smtp.gmail.com"
                                value={netDataInfo.data.smtp.server}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'server',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <Text>SMTP Port</Text>
                            <Input
                                type="number"
                                placeholder="587"
                                value={netDataInfo.data.smtp.port}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'port',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <Checkbox
                                checked={!!netDataInfo.data.smtp.allowNonTls}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'allowNonTls',
                                        e.target.checked
                                    )
                                }
                            >
                                Allow non-TLS
                            </Checkbox>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>SMTP Username</Text>
                            <Input
                                type="text"
                                placeholder="alerts.receiver@example.com"
                                value={netDataInfo.data.smtp.username}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'username',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>SMTP Password</Text>
                            <PasswordField
                                defaultValue={netDataInfo.data.smtp.password}
                                onChange={(e) =>
                                    this.changeModel(
                                        'smtp',
                                        'password',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                <br />

                <Card title="Slack">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Text>Slack Webhook</Text>
                            <Input
                                type="text"
                                placeholder="https://hooks.slack.com/services/XXXX"
                                value={netDataInfo.data.slack.hook}
                                onChange={(e) =>
                                    this.changeModel(
                                        'slack',
                                        'hook',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>Slack Channel</Text>
                            <Input
                                type="text"
                                placeholder="alertschannel"
                                value={netDataInfo.data.slack.channel}
                                onChange={(e) =>
                                    this.changeModel(
                                        'slack',
                                        'channel',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                <br />

                <Card title="Telegram">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Text>Bot Token</Text>
                            <Input
                                type="text"
                                placeholder="TELEGRAM_BOT_TOKEN"
                                value={netDataInfo.data.telegram.botToken}
                                onChange={(e) =>
                                    this.changeModel(
                                        'telegram',
                                        'botToken',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>Chat ID</Text>
                            <Input
                                type="text"
                                placeholder="Telegram Chat ID"
                                value={netDataInfo.data.telegram.chatId}
                                onChange={(e) =>
                                    this.changeModel(
                                        'telegram',
                                        'chatId',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                <br />

                <Card title="Push Bullet">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Text>Push Bullet API Token</Text>
                            <Input
                                type="text"
                                placeholder="PUSH_BULLET_API_TOKEN"
                                value={netDataInfo.data.pushBullet.apiToken}
                                onChange={(e) =>
                                    this.changeModel(
                                        'pushBullet',
                                        'apiToken',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Text>Default Email (fallback receiver)</Text>
                            <Input
                                type="text"
                                placeholder="alerts.receiver@example.com"
                                value={
                                    netDataInfo.data.pushBullet.fallbackEmail
                                }
                                onChange={(e) =>
                                    this.changeModel(
                                        'pushBullet',
                                        'fallbackEmail',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}
