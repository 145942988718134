import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    LinkOutlined,
    LoadingOutlined,
    LockFilled,
    MoreOutlined,
    SyncOutlined,
    UnlockFilled,
} from '@ant-design/icons'
import {
    Card,
    Divider,
    Dropdown,
    Flex,
    Input,
    MenuProps,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { History } from 'history'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ApiManager from '../../api/ApiManager'
import { IMobileComponent } from '../../models/ContainerProps'
import '../../styles/style.css'
import { styles } from '../../styles/styles'
import { localize } from '../../utils/Language'
import Logger from '../../utils/Logger'
import NewTabLink from '../global/NewTabLink'
import { IAppDef } from './AppDefinition'
import CreateNewApp from './CreateNewApp'

type TableData = IAppDef & { lastDeployTime: string }

class AppsTable extends Component<
    {
        history: History
        apps: IAppDef[]
        apiManager: ApiManager
        onCreateNewAppClicked: (
            appName: string,
            hasPersistentData: boolean
        ) => void
        onReloadRequested: () => void
        rootDomain: string
        defaultNginxConfig: string
        isMobile: boolean
        search: string | undefined
        apiData:
            | {
                  appDefinitions: IAppDef[]
                  defaultNginxConfig: string
                  rootDomain: string
              }
            | undefined
    },
    {
        searchTerm: string
        isBulkEditMode: boolean
        selectedRowKeys: React.Key[]
        selectedApp: string
        // isLoading: boolean
    }
> {
    constructor(props: any) {
        super(props)
        const urlsQuery = new URLSearchParams(props.search || '').get('q') || ''
        this.state = {
            searchTerm: urlsQuery,
            isBulkEditMode: false,
            selectedRowKeys: [],
            selectedApp: '',
        }
    }

    appDetailPath(appName: string) {
        return `/apps/details/${appName}`
    }

    createColumns() {
        const self = this
        const ALIGN: 'center' = 'center'

        const columns: ColumnProps<TableData>[] = [
            {
                title: localize('apps_table.app_name', 'App Name'),
                dataIndex: 'appName',
                key: 'appName',
                render: (appName: string) => (
                    <Link
                        to={this.appDetailPath(appName)}
                        style={{
                            color: 'white',
                            fontWeight: '700',
                        }}
                    >
                        <Typography className="apps-table-link">
                            {appName}
                        </Typography>
                    </Link>
                ),
                sorter: (a, b) => {
                    return a.appName
                        ? a.appName.localeCompare(b.appName || '')
                        : 0
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: localize(
                    'apps_table.persistent_data',
                    'Persistent Data'
                ),
                dataIndex: 'hasPersistentData',
                key: 'hasPersistentData',
                align: ALIGN,
                render: (hasPersistentData: boolean) => {
                    if (!hasPersistentData) {
                        return (
                            <span>
                                <CloseOutlined />
                            </span>
                        )
                    }
                    return (
                        <span>
                            <CheckOutlined />
                        </span>
                    )
                },
            },
            {
                title: 'Build',
                dataIndex: 'isAppBuilding',
                key: 'isAppBuilding',
                align: ALIGN,
                width: '12.5%',
                render: (isAppBuilding: boolean) => {
                    return (
                        <>
                            {!isAppBuilding ? (
                                <Typography>
                                    <CheckCircleOutlined
                                        style={{ color: styles.success }}
                                    />{' '}
                                    Complete
                                </Typography>
                            ) : (
                                <Typography>
                                    <SyncOutlined
                                        spin
                                        style={{ color: styles.warning }}
                                    />{' '}
                                    Building
                                </Typography>
                            )}
                        </>
                    )
                },
            },
            {
                title: localize('apps_table.instance_count', 'Instance Count'),
                dataIndex: 'instanceCount',
                key: 'instanceCount',
                align: ALIGN,
            },
            {
                title: 'Force SSL',
                dataIndex: 'forceSsl',
                key: 'forceSsl',
                align: ALIGN,
                render: (_: any, app: TableData) => (
                    <>
                        {app.forceSsl ? (
                            <LockFilled style={{ color: styles.success }} />
                        ) : (
                            <UnlockFilled style={{ color: styles.danger }} />
                        )}
                    </>
                ),
            },
            {
                title: localize('apps_table.tags', 'Tags'),
                dataIndex: 'tags',
                key: 'tags',
                align: ALIGN,
                width: '18%',
                render: (_: any, app: TableData) => {
                    return (
                        <Fragment>
                            {app.tags && app.tags.length > 0 ? (
                                app.tags.map((it) => (
                                    <Tag
                                        key={it.tagName}
                                        color={getRandomBackgroundAndTextColor()}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            self.setState({
                                                searchTerm: 'tag:' + it.tagName,
                                            })
                                        }}
                                    >
                                        {it.tagName}
                                    </Tag>
                                ))
                            ) : (
                                <span></span>
                            )}
                        </Fragment>
                    )
                },
            },
            {
                title: localize('apps_table.last_deployed', 'Last Deployed'),
                dataIndex: 'lastDeployTime',
                key: 'lastDeployTime',
                align: ALIGN,
                sorter: (a, b) => {
                    return (
                        Date.parse(a.lastDeployTime) -
                        Date.parse(b.lastDeployTime)
                    )
                },
                sortDirections: ['descend', 'ascend'],
                render: (lastDeployTime: string, app) => {
                    if (!lastDeployTime) {
                        return <span />
                    }

                    return (
                        <span>
                            <Tooltip
                                // style={{padding: "2em"}}
                                title={
                                    <div
                                        style={{
                                            padding: '0.5em',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography>
                                            {new Date(
                                                lastDeployTime
                                            ).toLocaleString()}
                                        </Typography>
                                        <Typography>
                                            {moment(
                                                new Date(lastDeployTime)
                                            ).fromNow()}
                                        </Typography>
                                    </div>
                                }
                            >
                                <Typography>
                                    {new Date(
                                        lastDeployTime
                                    ).toLocaleDateString('en-US', {
                                        weekday: 'short',
                                        day: 'numeric',
                                        month: 'short',
                                    })}
                                </Typography>
                            </Tooltip>
                            {!!app.isAppBuilding ? (
                                <LoadingOutlined
                                    style={{
                                        marginLeft: 12,
                                    }}
                                />
                            ) : undefined}
                        </span>
                    )
                },
            },
            {
                title: '',
                dataIndex: 'more',
                key: 'more',
                align: ALIGN,
                render: (notExposeAsWebApp: boolean, app) => {
                    const items: MenuProps['items'] = [
                        {
                            key: '1',
                            label: (
                                <Link
                                    // target="_blank"
                                    // href={this.appDetailPath(app.appName!)}
                                    to={this.appDetailPath(app.appName!)}
                                >
                                    Edit
                                </Link>
                            ),
                            icon: <EditOutlined />,
                        },
                        {
                            key: '2',
                            label: (
                                <NewTabLink
                                    url={`http${
                                        app.hasDefaultSubDomainSsl ? 's' : ''
                                    }://${app.appName}.${
                                        self.props.rootDomain
                                    }`}
                                >
                                    <Typography>Open</Typography>
                                </NewTabLink>
                            ),
                            icon: <LinkOutlined />,
                            disabled: notExposeAsWebApp,
                        },
                    ]

                    return (
                        <Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
                                <MoreOutlined
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            </a>
                        </Dropdown>
                    )
                },
            },
        ]

        return columns
    }

    render() {
        const self = this

        const appsToRender = self.props.apps
            .filter((app) => {
                const searchTerm = self.state.searchTerm
                if (!searchTerm) return true

                if (searchTerm.startsWith('tag:')) {
                    const entries = searchTerm.substring(4).split(' ')
                    const tagToFilter = entries[0]
                    const tagExists =
                        (app.tags || []).filter((t) =>
                            t.tagName.startsWith(tagToFilter)
                        ).length > 0
                    if (entries.length > 1) {
                        const appNameToFilter = searchTerm
                            .substring(4)
                            .split(' ')[1]
                        return (
                            tagExists &&
                            app.appName!.indexOf(appNameToFilter) >= 0
                        )
                    }
                    return tagExists
                }

                return app.appName!.indexOf(searchTerm) >= 0
            })
            .map((app) => {
                let versionFound = app.versions.filter(
                    (v) => v.version === app.deployedVersion
                )

                let lastDeployTime = ''

                if (versionFound.length === 0) {
                    // See https://github.com/SudoShip/SudoShip-frontend/issues/56
                    // This can happen when user creates a new app while a build is in progress.
                    // This results in app.versions being an empty array until the 0th version gets deployed.
                    Logger.error(
                        `App ${app.appName} has invalid deployVersion=${
                            app.deployedVersion
                        }, versions:${JSON.stringify(app.versions)}`
                    )
                } else {
                    lastDeployTime = versionFound[0].timeStamp || ''
                }

                return { ...app, lastDeployTime }
            })

        const searchAppInput = (
            <Input
                placeholder={localize(
                    'apps_table.search_input_placeholder',
                    'Search by Name'
                )}
                type="text"
                value={self.state.searchTerm}
                defaultValue={self.state.searchTerm}
                onChange={(event) =>
                    self.setState({
                        searchTerm: (event.target.value || '')
                            .trim()
                            .toLowerCase(),
                    })
                }
            />
        )

        return (
            <Flex
                style={{
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                {self.props.isMobile ? (
                    <Flex
                        style={{
                            width: '100%',
                            flexDirection: 'column',
                            gap: '1em',
                            alignItems: 'center',
                        }}
                    >
                        {appsToRender.map(
                            ({
                                appName = '',
                                hasPersistentData,
                                notExposeAsWebApp,
                                isAppBuilding,
                                instanceCount,
                                forceSsl,
                                hasDefaultSubDomainSsl,
                            }) => (
                                <Card
                                    type="inner"
                                    title={appName}
                                    key={appName}
                                    extra={
                                        <Link to={this.appDetailPath(appName)}>
                                            Details
                                        </Link>
                                    }
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Typography>
                                        Persistent Data:{' '}
                                        {!hasPersistentData ? undefined : (
                                            <span>
                                                <CheckOutlined />
                                            </span>
                                        )}
                                    </Typography>
                                    <Typography>
                                        Exposed Webapp:{' '}
                                        {!!notExposeAsWebApp ? undefined : (
                                            <span>
                                                <CheckOutlined />
                                            </span>
                                        )}
                                    </Typography>
                                    <Typography>
                                        Instance Count: {instanceCount}
                                    </Typography>
                                    <div>Force SSL: {forceSsl}</div>
                                    <Typography>
                                        Open in Browser:{' '}
                                        {!!notExposeAsWebApp ? undefined : (
                                            <NewTabLink
                                                url={`http${
                                                    hasDefaultSubDomainSsl
                                                        ? 's'
                                                        : ''
                                                }://${appName}.${
                                                    self.props.rootDomain
                                                }`}
                                            >
                                                <LinkOutlined />{' '}
                                            </NewTabLink>
                                        )}
                                    </Typography>
                                </Card>
                            )
                        )}
                    </Flex>
                ) : (
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <Flex
                            style={{
                                justifyContent: 'space-between',
                                marginBottom: 16,
                            }}
                        >
                            <Input
                                style={{
                                    width: 'auto', // Ajusta a largura automaticamente
                                    minWidth: '100px', // Largura mínima menor que o botão
                                    height: '35px', // Altura menor que o botão
                                    padding: '8px 16px', // Padding ajustado para ficar menor
                                    fontSize: '14px', // Tamanho da fonte ligeiramente menor
                                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)', // Sombra leve
                                    marginTop: '14px', // Margem superior
                                    marginBottom: '0px', // Reduzindo ou removendo margem inferior para ficar mais próximo da tabela
                                }}
                                placeholder={localize(
                                    'apps_table.search_input_placeholder',
                                    'Search by Name'
                                )}
                                type="text"
                                value={self.state.searchTerm}
                                defaultValue={self.state.searchTerm}
                                onChange={(event) =>
                                    self.setState({
                                        searchTerm: (event.target.value || '')
                                            .trim()
                                            .toLowerCase(),
                                    })
                                }
                            />
                            <CreateNewApp
                                onCreateNewApp={
                                    this.props.onCreateNewAppClicked
                                }
                                mobile={this.props.isMobile}
                            />
                        </Flex>
                        <Table<TableData>
                            className="apps-table"
                            rowKey="appName"
                            columns={self.createColumns()}
                            dataSource={appsToRender}
                            pagination={false}
                            size={'large'}
                            footer={() => <></>}
                            expandable={{
                                expandedRowRender: (record) => {
                                    const customDomains =
                                        record.customDomain || []

                                    if (!self.props.apiData) {
                                        return
                                    }

                                    const app =
                                        self.props.apiData.appDefinitions.find(
                                            (a) => a.appName === record.appName
                                        )
                                    if (app === undefined) {
                                        return
                                    }

                                    return (
                                        <div>
                                            <Typography
                                                style={{
                                                    fontSize: '1.6em',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Details
                                            </Typography>
                                            <Divider style={{ marginTop: 0 }} />
                                            <Typography>
                                                Domain: &nbsp;
                                                <NewTabLink
                                                    url={`http${
                                                        app.hasDefaultSubDomainSsl
                                                            ? 's'
                                                            : ''
                                                    }://${app.appName}.${
                                                        self.props.apiData!
                                                            .rootDomain
                                                    }`}
                                                >
                                                    {`http${
                                                        app.hasDefaultSubDomainSsl
                                                            ? 's'
                                                            : ''
                                                    }://${app.appName}.${
                                                        self.props.apiData!
                                                            .rootDomain
                                                    }`}
                                                </NewTabLink>
                                            </Typography>
                                        </div>
                                    )
                                },
                            }}
                            rowSelection={
                                self.state.isBulkEditMode
                                    ? {
                                          selectedRowKeys:
                                              self.state.selectedRowKeys,
                                          onChange: (
                                              newSelectedRowKeys: React.Key[]
                                          ) => {
                                              self.setState({
                                                  selectedRowKeys:
                                                      newSelectedRowKeys,
                                              })
                                          },
                                      }
                                    : undefined
                            }
                            onChange={(pagination, filters, sorter) => {
                                // Persist sorter state
                                if (!Array.isArray(sorter)) {
                                    window.localStorage.appsSortKey =
                                        sorter.columnKey
                                    window.localStorage.appsSortOrder =
                                        sorter.order
                                }
                            }}
                        />
                    </div>
                )}
            </Flex>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect<IMobileComponent, any, any>(
    mapStateToProps,
    undefined
)(AppsTable)

function getRandomBackgroundAndTextColor(): string {
    const tagColors: string[] = [
        'magenta',
        'red',
        'volcano',
        'orange',
        'gold',
        'lime',
        'green',
        'cyan',
        'blue',
        'geekblue',
        'purple',
        'pink',
        'yellow',
    ]

    const randomIndex = Math.floor(Math.random() * tagColors.length)
    return tagColors[randomIndex]
}
