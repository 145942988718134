import { Collapse, Flex, Typography } from 'antd'
import { Component } from 'react'

const { Panel } = Collapse

export default class DockerRegistriesStaticInfo extends Component {
    render() {
        return (
            <Flex style={{flexDirection: "column"}} gap="middle">
                <Typography>
                    Setting up Docker Registry is <b>only required</b> if you
                    plan to run your SudoShip on a cluster. For single node
                    SudoShip deployments, Docker Registry is not recommended as
                    it makes deployment significantly slower.
                </Typography>
                <Collapse >
                    <Panel header="More info" key="1">
                        <Typography>
                            Docker registry is a repository for your built
                            images. It is similar to GitHub or Bitbucket, with
                            private repositories. However, instead of source
                            code, it contains the built artifacts for your
                            application. It is required for cluster mode, as
                            other nodes need to access the built image in order
                            for your application to run on them.
                        </Typography>
                        <Typography>
                            SudoShip provides two methods for you to set up your
                            Docker registry:
                        </Typography>
                        <ul style={listStyle}>
                            <li>
                                <b>Self-hosted Docker Registry:</b> This is the
                                simplest way to set up a Docker registry.
                                SudoShip creates an instance of Docker Registry
                                on the main machine and it manages the registry
                                for you. However, it has its own limitations. If
                                your main machine is destroyed, your local
                                images will be lost. In most cases, this is not
                                a disaster as you can redeploy your apps from
                                your source code.
                            </li>
                            <li>
                                <b>Remote Docker Registry:</b> This approach
                                relies on a remote service to act as your Docker
                                Registry. Using this approach, you'll have a
                                more reliable cluster, assuming the third-party
                                service you use is reliable! There are multiple
                                private Docker Registry services available:
                                Google Container Registry, Amazon EC2 Container
                                Registry, Quay, etc. Note that this approach
                                costs you money.
                            </li>
                        </ul>
                    </Panel>
                </Collapse>
            </Flex>
        )
    }
}

// Estilos Inline
const listStyle = {
    paddingLeft: '20px',
}
