import * as Icons from '@ant-design/icons'
import { Avatar, Tooltip } from 'antd'
import { Component } from 'react'
import Toaster from '../../utils/Toaster'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'

class LoadBalancerStatsCard extends Component<any, any> {
    render() {
        const cardStyle: React.CSSProperties = {
            height: 240,
            overflow: 'hidden',
            borderRadius: '8px',
            marginBottom: 20,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column' as 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
        }

        const avatarStyle: React.CSSProperties = {
            backgroundColor: this.props.color,
            marginBottom: '16px',
        }

        const titleStyle: React.CSSProperties = {
            marginBottom: '8px',
            fontSize: '18px',
            fontWeight: 'bold',
        }

        const textStyle: React.CSSProperties = {
            color: '#777',
        }

        return (
            <div style={cardStyle}>
                <div style={{ textAlign: 'center', margin: 16 }}>
                    <Avatar
                        style={avatarStyle}
                        size={64}
                        icon={this.props.icon}
                    />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={titleStyle}>
                        {this.props.titleText}
                        <br />
                        {this.props.titleNumber}
                    </h4>
                    <p style={textStyle}>
                        {this.props.text1}
                        <br />
                        {this.props.text2}
                    </p>
                </div>
            </div>
        )
    }
}

export default class LoadBalancerStats extends ApiComponent<
    {},
    { apiData: any; isLoading: boolean }
> {
    private updateApiInterval: any

    constructor(props: any) {
        super(props)
        this.state = {
            apiData: undefined,
            isLoading: true,
        }
    }

    updateApi() {
        const self = this
        self.setState({ isLoading: !self.state.apiData }) // set to true just the first time
        this.apiManager
            .getLoadBalancerInfo()
            .then(function (data) {
                self.setState({ apiData: data })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    componentWillUnmount() {
        // @ts-ignore
        if (super.componentWillUnmount) {
            super.componentWillUnmount()
        }
        if (this.updateApiInterval) {
            clearInterval(this.updateApiInterval)
        }
    }

    componentDidMount() {
        const self = this
        this.updateApi()
        this.updateApiInterval = setInterval(function () {
            self.updateApi()
        }, 20000)
    }

    render() {
        if (!!this.state.isLoading) {
            return <CenteredSpinner />
        }

        if (!this.state.apiData) {
            return <ErrorRetry />
        }

        const containerStyle: React.CSSProperties = {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            gap: '16px',
        }

        return (
            <div style={containerStyle}>
                <Tooltip title="Constantly going up as refreshing the values">
                    <LoadBalancerStatsCard
                        icon={<Icons.DatabaseOutlined />}
                        color="#3f51b5"
                        titleText="Total Requests"
                        titleNumber={`${this.state.apiData.total}`}
                        text1={``}
                        text2={``}
                    />
                </Tooltip>
                <LoadBalancerStatsCard
                    icon={<Icons.LinkOutlined />}
                    color="#ff5722"
                    titleText="Active Connections"
                    titleNumber={`${this.state.apiData.activeConnections}`}
                    text1={`${this.state.apiData.handled} handled`}
                    text2={`${this.state.apiData.accepted} accepted`}
                />
                <LoadBalancerStatsCard
                    icon={<Icons.BellOutlined />}
                    color="#4caf50"
                    titleText="Active Requests"
                    titleNumber={`${
                        this.state.apiData.reading + this.state.apiData.writing
                    }`}
                    text1={`${this.state.apiData.reading} reading`}
                    text2={`${this.state.apiData.writing} writing`}
                />
                <LoadBalancerStatsCard
                    icon={<Icons.HourglassOutlined />}
                    color="#f44336"
                    titleText="Waiting Requests"
                    titleNumber={`${this.state.apiData.waiting}`}
                    text1={`  `}
                    text2={`  `}
                />
            </div>
        )
    }
}
