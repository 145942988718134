import { Card, Layout, Tabs } from 'antd'
import React from 'react'
import LoadBalancerStats from './LoadBalancerStats'
import NetDataInfo from './NetDataInfo'

const { Content } = Layout
const { TabPane } = Tabs

const useMobileDetect = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768)

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile
}

const Monitoring: React.FC = () => {
    const isMobile = useMobileDetect()

    const cardStyle = {
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        transition: 'all 0.3s ease',
    }

    const contentStyle = {
        margin: isMobile ? '8px' : '16px',
        padding: isMobile ? '8px' : '16px',
        transition: 'all 0.3s ease',
    }

    const tabBarStyle = {
        marginBottom: '16px',
    }

    return (
        <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <Card style={{ width: '80%' }}>
                <Tabs defaultActiveKey="1" tabBarStyle={tabBarStyle}>
                    <TabPane tab="Load Balancer Stats" key="1">
                        <LoadBalancerStats />
                    </TabPane>
                    <TabPane tab="Net Data Info" key="2">
                        <NetDataInfo />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    )
}

export default Monitoring
