import { Flex, MenuProps } from 'antd'
import { RouteComponentProps } from 'react-router'
import Toaster from '../../utils/Toaster'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'
import { IAppDef } from './AppDefinition'
import AppsTable from './AppsTable'
import { SingleAppApiData } from './appDetails/AppDetails'

export default class Apps extends ApiComponent<
    RouteComponentProps<any>,
    {
        isLoading: boolean
        apiData:
            | {
                  appDefinitions: IAppDef[]
                  defaultNginxConfig: string
                  rootDomain: string
              }
            | undefined
        singleAppData: SingleAppApiData | undefined
    }
> {
    constructor(props: RouteComponentProps<any>) {
        super(props)
        this.state = {
            isLoading: true,
            apiData: undefined,
            singleAppData: undefined,
        }

        this.onCreateNewAppClicked = this.onCreateNewAppClicked.bind(this)
    }

    onCreateNewAppClicked(appName: string, hasPersistentData: boolean) {
        Promise.resolve()
            .then(() => {
                this.setState({ isLoading: true })
                return this.apiManager.registerNewApp(
                    appName,
                    hasPersistentData,
                    true
                )
            })
            .then(() => {
                return this.reFetchData()
            })
            .catch(Toaster.createCatcher())
            .then(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        const self = this

        if (self.state.isLoading) {
            return <CenteredSpinner />
        }

        const apiData = self.state.apiData

        if (!apiData) {
            return <ErrorRetry />
        }

        // const items: MenuProps['items'] = apiData.appDefinitions.map(
        //     (item, index) => {
        //         const key = String(index + 1)

        //         return {
        //             key: `app-${key}`,
        //             label: item.appName,

        //             children: new Array(4).fill(undefined).map((_, j) => {
        //                 const subKey = index * 4 + j + 1
        //                 return {
        //                     key: subKey,
        //                     label: `option${subKey}`,
        //                 }
        //             }),
        //         }
        //     }
        // )

        return (
            <Flex
                style={{
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <Flex
                    style={{
                        flexDirection: 'column',
                        gap: 25,
                        width: '80%',
                        alignItems: 'center',
                    }}
                >
                    {apiData.appDefinitions && (
                        <AppsTable
                            onReloadRequested={() => {
                                self.reFetchData()
                            }}
                            onCreateNewAppClicked={this.onCreateNewAppClicked}
                            search={self.props.location.search}
                            history={self.props.history}
                            apiManager={self.apiManager}
                            defaultNginxConfig={apiData.defaultNginxConfig}
                            apps={apiData.appDefinitions}
                            rootDomain={apiData.rootDomain}
                            apiData={apiData}
                        />
                    )}
                </Flex>
            </Flex>
        )
    }

    componentDidMount() {
        this.reFetchData()
    }

    reFetchData() {
        this.setState({ isLoading: true })
        return this.apiManager
            .getAllApps()
            .then((data: any) => {
                this.setState({ apiData: data })
                for (let index = 0; index < data.appDefinitions.length; index++) {
                    const element = data.appDefinitions[index]
                    if (element.appName === this.props.match.params.appName) {
                        this.setState({
                            isLoading: false,
                            singleAppData: {
                                appDefinition: element,
                                rootDomain: data.rootDomain,
                                captainSubDomain: data.captainSubDomain,
                                defaultNginxConfig: data.defaultNginxConfig,
                            },
                        })
                        return
                    }
                }
            })
            .catch(Toaster.createCatcher())
            .then(() => {
                this.setState({ isLoading: false })
            })
    }
    
}
