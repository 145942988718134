import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Input, Layout, Row } from 'antd'
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import ApiManager from '../api/ApiManager'
import '../styles/style.css'
import ErrorFactory from '../utils/ErrorFactory'
import { localize } from '../utils/Language'
import StorageHelper from '../utils/StorageHelper'
import Toaster from '../utils/Toaster'
import Utils from '../utils/Utils'
import Logo from './full-logo.png'
import ApiComponent from './global/ApiComponent'

const { Content } = Layout

const NO_SESSION = 1
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SESSION_STORAGE = 2
const LOCAL_STORAGE = 3

class NormalLoginForm extends React.Component<
    any,
    {
        loginOption: number
        passwordEntered: string
        otpEntered: string
        rememberMe: boolean
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            loginOption: LOCAL_STORAGE, // Default to LOCAL_STORAGE
            passwordEntered: '',
            otpEntered: '',
            rememberMe: false, // Default to false
        }
    }

    handleSubmit = (e?: React.FormEvent): void => {
        e?.preventDefault()
        const now = new Date().getTime()
        if (now - lastSubmittedTime < 300) return // avoid duplicate clicks
        lastSubmittedTime = now
        const self = this
        self.props.onLoginRequested(
            self.state.passwordEntered,
            self.state.otpEntered,
            self.state.loginOption
        )

        // Se Remember Me estiver marcado, armazene a senha no localStorage
        if (self.state.rememberMe) {
            localStorage.setItem(
                'rememberedPassword',
                self.state.passwordEntered
            )
        }
    }

    render() {
        const self = this
        return (
            <form onSubmit={this.handleSubmit}>
                <div style={{ marginBottom: 8 }}>
                    <label className="custom-password-label">
                        {localize('login_form.password_label', 'Password')}
                    </label>
                </div>
                <Input.Password
                    required
                    onKeyDown={(key) => {
                        if (
                            `${key.key}`.toLocaleLowerCase() === 'enter' ||
                            `${key.code}`.toLocaleLowerCase() === 'enter' ||
                            key.keyCode === 13
                        ) {
                            self.handleSubmit()
                        }
                    }}
                    prefix={
                        <LockOutlined
                            style={{ color: 'rgba(255,255,255,.25)' }}
                        />
                    }
                    onChange={(e) => {
                        self.setState({ passwordEntered: `${e.target.value}` })
                    }}
                    placeholder={localize(
                        'login_form.password_placeholder',
                        'Enter your password here...'
                    )}
                    autoFocus
                    style={{
                        backgroundColor: 'rgba(20,20,20,255)', // Adjusted background color
                        borderColor: 'rgba(255, 255, 255, 0.2)',
                        color: '#fff', // White text
                        borderRadius: '8px',
                        height: '48px',
                        fontSize: '1rem',
                        fontFamily: 'Jost',
                    }}
                />
                <Checkbox
                    checked={this.state.rememberMe}
                    onChange={(e) =>
                        this.setState({ rememberMe: e.target.checked })
                    }
                    className="custom-checkbox-label"
                >
                    {localize('login_form.remember_me', 'Remember Me')}
                </Checkbox>

                {self.props.hasOtp ? (
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <Row justify="end">
                            <Input
                                onKeyDown={(key) => {
                                    if (
                                        `${key.key}`.toLocaleLowerCase() ===
                                            'enter' ||
                                        `${key.code}`.toLocaleLowerCase() ===
                                            'enter' ||
                                        key.keyCode === 13
                                    ) {
                                        self.handleSubmit()
                                    }
                                }}
                                addonBefore="OTP Verification Code"
                                placeholder="123456"
                                value={self.state.otpEntered}
                                onChange={(e) => {
                                    self.setState({
                                        otpEntered: `${e.target.value}`,
                                    })
                                }}
                                style={{
                                    fontFamily: 'Jost',
                                    backgroundColor: 'rgba(20,20,20,255)', // Adjusted background color
                                    borderRadius: '8px',
                                    height: '48px',
                                    fontSize: '1rem',
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                    color: '#fff', // White text
                                }}
                            />
                        </Row>
                    </div>
                ) : undefined}

                <div style={{ marginTop: 30, marginBottom: 20 }}>
                    <Row justify="end">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="custom-button"
                        >
                            {localize('login_form.login', 'Login')}
                        </Button>
                    </Row>
                </div>
            </form>
        )
    }
}

export default class Login extends ApiComponent<RouteComponentProps<any>, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            loginOption: NO_SESSION,
            hasOtp: false,
        }
    }

    componentDidMount(): void {
        if (super.componentDidMount) {
            super.componentDidMount()
        }
        Utils.deleteAllCookies()
    }

    onLoginRequested(password: string, otp: string) {
        const self = this
        this.apiManager
            .getAuthToken(password, otp)
            .then(function () {
                // Sempre armazena o token no localStorage
                StorageHelper.setAuthKeyInLocalStorage(
                    ApiManager.getAuthTokenString()
                )
                self.props.history.push('/')
            })
            .catch((error) => {
                if (
                    error.captainStatus ===
                    ErrorFactory.STATUS_ERROR_OTP_REQUIRED
                ) {
                    self.setState({
                        hasOtp: true,
                    })
                    Toaster.toastInfo('Enter OTP Verification Code')
                } else {
                    throw error
                }
            })
            .catch(Toaster.createCatcher())
    }

    render() {
        const self = this

        if (ApiManager.isLoggedIn()) return <Redirect to="/" />

        return (
            <Layout className="full-screen">
                <Content
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100vw',
                    }}
                >
                    <div
                        style={{
                            background: `linear-gradient(to top, #000000, #003366)`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            padding: '1rem',
                        }}
                    >
                        <Card
                            title={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '1rem',
                                        boxShadow:
                                            '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        textAlign: 'center',
                                        fontFamily: 'Jost',
                                    }}
                                >
                                    <img
                                        src={Logo}
                                        alt="Logo"
                                        style={{
                                            width: '150px', // Adjust as needed
                                            height: 'auto',
                                        }}
                                    />
                                </div>
                            }
                            style={{
                                width: '90%', // Adjust width for mobile responsiveness
                                maxWidth: '480px', // Max width for larger screens
                                padding: '1rem',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                background: 'rgba(0, 0, 0, 0.3)',
                                border: 'none',
                                borderRadius: '20px',
                                fontFamily: 'Jost',
                            }}
                            headStyle={{ border: 'none' }}
                            bodyStyle={{ borderRadius: '20px' }}
                        >
                            <NormalLoginForm
                                onLoginRequested={(
                                    password: string,
                                    otp: string,
                                    loginOption: number
                                ) => {
                                    self.onLoginRequested(password, otp)
                                }}
                                hasOtp={self.state.hasOtp}
                            />
                        </Card>
                    </div>
                </Content>
            </Layout>
        )
    }
}

let lastSubmittedTime = 0
