import { Card, Col, Flex, Row } from 'antd'
import { Component } from 'react'
import DockerRegistries from './DockerRegistries'
import Nodes from './Nodes'

export default class Cluster extends Component {
    render() {

        return (
            <Flex style={{flexDirection: "column", width: "100%", alignItems: "center"}} gap="middle">
                
                        <Card
                            title={"Docker Registry Configuration"}
                            style={{width: "80%"}}
                        >
                            <DockerRegistries />
                        </Card>
                        <Card
                            title={"Nodes"}
                            style={{width: "80%"}}
                        >
                            <Nodes />
                        </Card>
            </Flex>
        )
    }
}
