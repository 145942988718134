import { App as AntdApp, ConfigProvider, theme } from 'antd'
import { useState } from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import Login from './containers/Login'
import PageRoot from './containers/PageRoot'
import DarkModeContext from './contexts/DarkModeContext'
import reducers from './redux/reducers'
import './styles/style.css'
import CrashReporter from './utils/CrashReporter'
import { currentLanguageOption } from './utils/Language'
import StorageHelper from './utils/StorageHelper'

CrashReporter.getInstance().init()

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(reducers)

const MainComponent = () => {
    return (
        <AntdApp className="full-screen">
            <HashRouter>
                <Switch>
                    <Route path="/login/" component={Login} />
                    <Route path="/" component={PageRoot} />
                </Switch>
            </HashRouter>
        </AntdApp>
    )
}

function App() {
    const { defaultAlgorithm, darkAlgorithm } = theme
    const [isDarkMode, setIsDarkMode] = useState(
        StorageHelper.getDarkModeFromLocalStorage()
    )

    return (
        <ConfigProvider
            theme={{
                algorithm: darkAlgorithm,
                token: {
                    colorBgBase: '#0B1624',
                    colorBgContainer: '#FFFFFF07',
                    colorBorder: '#6a7f9b50',
                    colorBorderBg: '#6a7f9b50',
                    colorBorderSecondary: '#6a7f9b50',
                    colorPrimary: '#1b8ad3',
                    colorLink: '#1b8ad3',
                    fontFamily: 'Inter',
                    borderRadius: 5,
                    fontSize: 14
                },
                components: {
                    Modal: {    
                        contentBg: '#0B1624',
                        colorBgLayout: 'red',
                        headerBg: '#0B1624'
                    },
                    Button: {
                        colorPrimary: '#0B1624', // Cor de fundo principal
                        colorText: '#ffffff', // Cor do texto
                        colorBorder: '#6a7f9b50', // Borda acinzentada
                        colorPrimaryHover: '#09121c', // Tom azulado mais escuro no hover
                        colorPrimaryActive: '#070e17', // Tom azulado ainda mais escuro no active
                        colorBgTextHover: '#f5f5f5', // Texto mais branco no hover
                        colorBgTextActive: '#ffffff', // Texto mais branco no active
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra sutil,
                        borderRadius: 15,
                    },
                    Table: {
                        colorBgContainer: '#00000003'
                    }

                },
            }}
            locale={currentLanguageOption.antdLocale}
        >
            <DarkModeContext.Provider
                value={{
                    isDarkMode,
                    setIsDarkMode: (value) => {
                        setIsDarkMode(value)
                        StorageHelper.setDarkModeInLocalStorage(value)
                    },
                }}
            >
                <Provider store={store}>
                    <MainComponent />
                </Provider>
            </DarkModeContext.Provider>
        </ConfigProvider>
    )
}

export default App
