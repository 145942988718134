import { Card, Collapse, Layout, Tabs } from 'antd'
import React from 'react'
import AutomaticDiskCleanup from './AutomaticDiskCleanup'
import BackupCreator from './BackupCreator'
import ChangePass from './ChangePass'
import CheckUpdate from './CheckUpdate'
import DiskCleanup from './DiskCleanup'
import NginxConfig from './NginxConfig'

const { Content } = Layout
const { TabPane } = Tabs

const useMobileDetect = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768)

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile
}

const Settings: React.FC = () => {
    const isMobile = useMobileDetect()

    const tabBarStyle = {
        marginBottom: '16px',
    }

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Card style={{width: "80%"}}>
                <Tabs defaultActiveKey="1" tabBarStyle={tabBarStyle}>
                    <TabPane tab="Updates" key="1">
                        <CheckUpdate isMobile={isMobile} />
                    </TabPane>
                    <TabPane tab="Backup" key="2">
                        <BackupCreator isMobile={isMobile} />
                    </TabPane>
                    <TabPane tab="Nginx Config" key="3">
                        <NginxConfig isMobile={isMobile} />
                    </TabPane>
                    <TabPane tab="Password" key="4">
                        <ChangePass isMobile={isMobile} />
                    </TabPane>
                    <TabPane tab="Cleanup" key="5">
                        <AutomaticDiskCleanup isMobile={isMobile} />
                        <Collapse
                            items={[
                                {
                                    key: '1',
                                    label: 'One off cleanup',
                                    children: (
                                        <DiskCleanup isMobile={isMobile} />
                                    ),
                                },
                            ]}
                        />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    )
}

export default Settings
