import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Flex,
    Input,
    Modal,
    Row,
    Tooltip,
    Typography,
} from 'antd'
import { Redirect, RouteComponentProps } from 'react-router'
import AppConstants from '../utils/AppConstants'
import Toaster from '../utils/Toaster'
import ApiComponent from './global/ApiComponent'
import CenteredSpinner from './global/CenteredSpinner'
import ErrorRetry from './global/ErrorRetry'
import NewTabLink from './global/NewTabLink'

const Search = Input.Search

export default class Dashboard extends ApiComponent<
    RouteComponentProps<any>,
    {
        isLoading: boolean
        isForceChangingDomain: boolean
        apiData: any
        userEmail: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            userEmail: '',
            isLoading: true,
            isForceChangingDomain: false,
            apiData: undefined,
        }
    }

    componentDidMount() {
        this.reFetchData()
    }

    reFetchData() {
        const self = this
        self.setState({ isLoading: true, apiData: undefined })
        return this.apiManager
            .getCaptainInfo()
            .then(function (data: any) {
                self.setState({ apiData: data })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    onForceSslClicked() {
        const self = this
        const isUsingHttp = window.location.href.startsWith('http://')

        Modal.confirm({
            title: 'Force HTTPS',
            content: (
                <Typography>
                    Once Force HTTPS is activated, all HTTP traffic is
                    redirected to HTTPS.
                    {isUsingHttp
                        ? 'Since this is a one-way action, and there is no revert, it is highly recommended that you test the HTTPS website first.'
                        : ''}{' '}
                    Do you still want to proceed?
                </Typography>
            ),
            onOk() {
                self.setState({ isLoading: true })
                self.apiManager
                    .forceSsl(true)
                    .then(function () {
                        Modal.success({
                            title: 'Force HTTPS activated!',
                            content: (
                                <div>
                                    <Typography>
                                        All HTTP traffic is now redirected to
                                        HTTPS.{' '}
                                        {isUsingHttp
                                            ? 'You will have to login again as you will now be redirected to HTTPS website.'
                                            : ''}
                                    </Typography>
                                </div>
                            ),
                            onOk() {
                                if (isUsingHttp) {
                                    window.location.replace(
                                        `https://${self.state.apiData.captainSubDomain}.${self.state.apiData.rootDomain}`
                                    )
                                }
                            },
                            onCancel() {
                                if (isUsingHttp) {
                                    window.location.replace(
                                        `https://${self.state.apiData.rootDomain}`
                                    )
                                }
                            },
                        })
                    })
                    .catch(Toaster.createCatcher())
                    .then(function () {
                        self.setState({ isLoading: false })
                    })
            },
            onCancel() {
                // do nothing
            },
        })
    }

    onEnableSslClicked() {
        const self = this
        const IGNORE = 'IGNORE'

        Promise.resolve()
            .then(function () {
                return new Promise(function (resolve, reject) {
                    Modal.success({
                        title: 'Enable HTTPS',
                        content: (
                            <div>
                                <Typography>
                                    SudoShip uses{' '}
                                    <NewTabLink url="https://letsencrypt.org/">
                                        Let&#39;s Encrypt
                                    </NewTabLink>{' '}
                                    to provide free SSL Certificates (HTTPS).
                                    This email address is very important as
                                    Let&#39;s Encrypt uses it for validation
                                    purposes. Please provide a valid email here.
                                </Typography>
                                <Typography>
                                    IMPORTANT: Once you enable HTTPS, you cannot
                                    edit the root domain ever again. Make sure
                                    you use a good root domain. A good practice
                                    is to go one level deeper and setup your
                                    root domain. For example, if you own{' '}
                                    <code style={{ whiteSpace: 'nowrap' }}>
                                        example.com
                                    </code>
                                    , use{' '}
                                    <code style={{ whiteSpace: 'nowrap' }}>
                                        *.SudoShip-root.example.com
                                    </code>{' '}
                                    as your root domain. This will allow you to
                                    better manage your subdomains, do not use{' '}
                                    <code style={{ whiteSpace: 'nowrap' }}>
                                        *.example.com
                                    </code>{' '}
                                    as your root domain.
                                </Typography>
                                <Input
                                    placeholder="your@email.com"
                                    type="email"
                                    onChange={(event) =>
                                        self.setState({
                                            userEmail: (
                                                event.target.value || ''
                                            ).trim(),
                                        })
                                    }
                                />
                            </div>
                        ),
                        onOk() {
                            resolve(self.state.userEmail || '')
                        },
                        onCancel() {
                            resolve(undefined)
                        },
                    })
                })
            })
            .then(function (data: any) {
                if (data === undefined) return IGNORE
                self.setState({ isLoading: true })
                return self.apiManager.enableRootSsl(data)
            })
            .then(function (data: any) {
                if (data === IGNORE) return

                Modal.success({
                    title: 'Root Domain HTTPS activated!',
                    content: (
                        <div>
                            <Typography>
                                You can now use{' '}
                                <code style={{ whiteSpace: 'nowrap' }}>
                                    {`https://${self.state.apiData.rootDomain}`}
                                </code>
                                . Next step is to Force HTTPS to disallow plain
                                HTTP traffic.
                            </Typography>
                        </div>
                    ),
                })

                return self.reFetchData()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    updateRootDomainClicked(rootDomain: string) {
        const self = this
        if (!self.state.apiData.hasRootSsl) {
            self.performUpdateRootDomain(rootDomain, false)
            return
        }

        Modal.confirm({
            title: 'Force Change Root Domain',
            content: (
                <div>
                    <Typography>
                        You have already enabled SSL for your root domain.
                        Changing the root domain URL will invalidate HTTPS on
                        root domain and all default subdomains for apps if you
                        have any apps.
                    </Typography>
                    <Typography>
                        You can still re-enable HTTPS after changing the root
                        domain.
                    </Typography>
                </div>
            ),
            onOk() {
                self.performUpdateRootDomain(rootDomain, true)
            },
            onCancel() {
                // do nothing
            },
        })
    }

    performUpdateRootDomain(rootDomain: string, force: boolean) {
        const self = this

        this.apiManager
            .updateRootDomain(rootDomain, force)
            .then(function (data: any) {
                Modal.success({
                    title: 'Root Domain Updated',
                    content: (
                        <div>
                            <Typography>
                                Click Ok to get redirected to your new root
                                domain. You need to log in again.
                            </Typography>
                        </div>
                    ),
                    onOk() {
                        window.location.replace(
                            `http://${self.state.apiData.captainSubDomain}.${rootDomain}`
                        )
                    },
                })
            })
            .catch(Toaster.createCatcher())
    }

    render() {
        const self = this

        if (self.state.isLoading) {
            return <CenteredSpinner />
        }

        if (!self.state.apiData) {
            return <ErrorRetry />
        }

        const qs = new URLSearchParams(self.props.location.search)

        if (
            !!this.state.apiData.forceSsl &&
            !!qs.get(AppConstants.REDIRECT_TO_APPS_IF_READY_REQ_PARAM)
        ) {
            return <Redirect to="/apps" />
        }

        return (
            <Flex gap="middle" vertical>
                {self.createInitialSetupIfNoRootSsl()}
                {self.createPostFullSetupIfHasForceSsl()}
                {self.createSetupPanelIfNoForceSsl()}
            </Flex>
        )
    }

    createSetupPanelIfNoForceSsl() {
        const self = this
        const { Text, Paragraph } = Typography
        if (this.state.apiData.forceSsl && !self.state.isForceChangingDomain) {
            // User has set up the machine, no need to update your domain again - unless user really wants this!
            return undefined
        }

        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card
                    title="SudoShip Root Domain Configurations"
                    style={{ width: '80%' }}
                >
                    <div>
                        <Typography>
                            <Paragraph>
                                The very first thing that{' '}
                                <Text strong>SudoShip</Text> needs is a root
                                domain. For example, if you own{' '}
                                <Text code>myawesomecompany.com</Text>, you can
                                use{' '}
                                <Text code>captain.myawesomecompany.com</Text>{' '}
                                or{' '}
                                <Text code>foo.bar.myawesomecompany.com</Text>{' '}
                                as your root domain.
                            </Paragraph>
                            <Paragraph>
                                First, you need to make sure that the IP address
                                for all subdomains of the root domain resolves
                                to the <Text strong>SudoShip</Text> IP address.
                                To do this, go to the DNS settings in your
                                domain provider's website and set a wildcard A
                                entry.
                            </Paragraph>
                            <Paragraph>
                                For example:
                                <Paragraph>
                                    <Text code>Type: A</Text>,{' '}
                                    <Text code>
                                        Name (or host): *.SudoShip-root
                                    </Text>
                                    ,{' '}
                                    <Text code>
                                        IP (or Points to): 110.120.130.140
                                    </Text>
                                </Paragraph>
                                where this is the IP address of your{' '}
                                <Text strong>SudoShip</Text> machine.
                            </Paragraph>
                        </Typography>
                        <Alert
                            type="info"
                            showIcon
                            message={
                                <Typography>
                                    NOTE: DNS settings might take several hours
                                    to take into effect. See{' '}
                                    <NewTabLink url="https://ca.godaddy.com/help/what-factors-affect-dns-propagation-time-1746">
                                        {' '}
                                        here
                                    </NewTabLink>{' '}
                                    for more details.
                                </Typography>
                            }
                        />
                    </div>
                    <Divider />
                    <Row>
                        <Flex gap="middle" vertical>
                            <Typography>
                                For example, if you set{' '}
                                <code style={{ whiteSpace: 'nowrap' }}>
                                    *.my-root.example.com
                                </code>{' '}
                                to the IP address of your server, just enter{' '}
                                <code style={{ whiteSpace: 'nowrap' }}>
                                    my-root.example.com
                                </code>{' '}
                                in the box below:
                            </Typography>
                            <div>
                                <Search
                                    addonBefore="[wildcard]&nbsp;."
                                    placeholder="my-root.example.com"
                                    defaultValue={
                                        self.state.apiData.rootDomain + ''
                                    }
                                    enterButton="Update Domain"
                                    onSearch={(value) =>
                                        self.updateRootDomainClicked(value)
                                    }
                                />
                            </div>
                        </Flex>
                    </Row>
                    <div style={{ height: 20 }} />
                    <Row justify="end">
                        <Tooltip title="Using Let's Encrypt Free Service">
                            <Button
                                disabled={
                                    self.state.apiData.hasRootSsl ||
                                    !self.state.apiData.rootDomain
                                }
                                onClick={() => self.onEnableSslClicked()}
                            >
                                Enable HTTPS
                            </Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <Tooltip title="Redirect all HTTP to HTTPS">
                            <Button
                                disabled={
                                    !self.state.apiData.hasRootSsl ||
                                    self.state.apiData.forceSsl
                                }
                                onClick={() => self.onForceSslClicked()}
                            >
                                Force HTTPS
                            </Button>
                        </Tooltip>
                    </Row>
                </Card>
            </div>
        )
    }

    createInitialSetupIfNoRootSsl() {
        const { Paragraph, Text } = Typography
        if (this.state.apiData.hasRootSsl) {
            // User has set up the machine, no need to show the welcome message
            return <></>
        }

        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card title="SudoOps Initial Setup" style={{ width: '80%' }}>
                    <Typography>
                        <Paragraph>
                            You have successfully installed SudoShip! However,{' '}
                            <Text strong>
                                you still need to assign a domain and complete
                                the HTTPS setup
                            </Text>{' '}
                            to fully configure SudoShip.
                        </Paragraph>
                        <Paragraph>
                            There are two ways to set up your SudoShip instance:
                        </Paragraph>
                        <Paragraph>
                            <Text strong>
                                1. Command Line Tool (RECOMMENDED):
                            </Text>{' '}
                            On your local machine, run{' '}
                            <Text code>npm i -g SudoShip</Text> followed by{' '}
                            <Text code>SudoShip serversetup</Text>. Then, follow
                            the guide.
                        </Paragraph>
                        <Paragraph>
                            <Text strong>2. Use the Panel Below:</Text> This is
                            a non-guided version of the Command Line method. Use
                            this method only for the purpose of experimentation.
                        </Paragraph>
                    </Typography>
                </Card>
            </div>
        )
    }

    createPostFullSetupIfHasForceSsl() {
        const self = this
        if (!this.state.apiData.forceSsl) {
            // User has not fully set up the machine, do not show the post installation message
            return undefined
        }

        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card title="SudoOps" style={{ width: '80%' }}>
                    <div>
                        <Typography>
                            You have installed and set SudoShip up successfully!
                            You can now deploy your apps! Remember, with
                            SudoShip, you can deploy applications from source
                            code (such as Node.js, PHP, Java, Ruby, Python etc),
                            and you can also deploy ready to go applications
                            such as MySQL, MongoDB, WordPress, Redis, and many
                            more!
                        </Typography>

                        <Typography>
                            For more information on how to deploy applications
                            from source code, make sure to have a look at our
                            <a
                                href="https://SudoShip.com/docs/sample-apps.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                sample apps.
                            </a>
                        </Typography>

                        <Typography>
                            <i>
                                You can always update your root domain, but be
                                careful! Your SSL certificates will get revoked
                                because of this domain change.
                            </i>
                        </Typography>

                        <Row justify="end">
                            <Button
                                disabled={this.state.isForceChangingDomain}
                                ghost
                                onClick={() => {
                                    self.setState({
                                        isForceChangingDomain: true,
                                    })
                                }}
                            >
                                Change Root Domain Anyways
                            </Button>
                        </Row>
                    </div>
                </Card>
            </div>
        )
    }
}
