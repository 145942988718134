import { EditOutlined, ReadOutlined, SaveOutlined } from '@ant-design/icons'
import {
    Alert,
    Button,
    Card,
    Divider,
    Flex,
    Input,
    Layout,
    Menu,
    Modal,
    Tabs,
    Tooltip,
    Typography,
} from 'antd'
import { RefObject } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import ApiManager from '../../../api/ApiManager'
import { IMobileComponent } from '../../../models/ContainerProps'
import { IHashMapGeneric } from '../../../models/IHashMapGeneric'
import Toaster from '../../../utils/Toaster'
import Utils from '../../../utils/Utils'
import ApiComponent from '../../global/ApiComponent'
import CenteredSpinner from '../../global/CenteredSpinner'
import ClickableLink from '../../global/ClickableLink'
import ErrorRetry from '../../global/ErrorRetry'
import { IAppDef } from '../AppDefinition'
import AppConfigs from './AppConfigs'
import HttpSettings from './HttpSettings'
import Deployment from './deploy/Deployment'
import Settings from './Settings'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import AppLogsView from './deploy/AppLogsView'

const WEB_SETTINGS = 'WEB_SETTINGS'
const APP_CONFIGS = 'APP_CONFIGS'
const DEPLOYMENT = 'DEPLOYMENT'
const LOGS = 'LOGS'
const SETTINGS = 'SETTINGS'

export interface SingleAppApiData {
    appDefinition: IAppDef
    rootDomain: string
    captainSubDomain: string
    defaultNginxConfig: string
}

export interface AppDetailsTabProps {
    apiData: SingleAppApiData
    apiManager: ApiManager
    updateApiData: Function
    onUpdateConfigAndSave: () => void
    reFetchData: () => void
    setLoading: (value: boolean) => void
    isMobile: boolean
}

interface PropsInterface extends RouteComponentProps<any> {
    mainContainer: RefObject<HTMLDivElement>
    isMobile: boolean
    // selectedApp: string
}

class AppDetails extends ApiComponent<
    PropsInterface,
    {
        isLoading: boolean
        apiData: SingleAppApiData | undefined
        activeTabKey: string
        renderCounterForAffixBug: number
        // selectedApp: string
    }
> {
    private reRenderTriggered = false
    private confirmedAppNameToDelete: string = ''
    private volumesToDelete: IHashMapGeneric<boolean> = {}

    constructor(props: PropsInterface) {
        super(props)

        this.state = {
            activeTabKey: WEB_SETTINGS,
            isLoading: true,
            renderCounterForAffixBug: 0,
            apiData: undefined,
        }
    }
   

    goBackToApps() {
        this.props.history.push('/apps')
    }

    openRenameAppDialog() {
        const self = this
        const app = self.state.apiData!.appDefinition
        const tempVal = { newName: app.appName }
        Modal.confirm({
            title: 'Rename the app:',
            content: (
                <div>
                    <Alert
                        type="warning"
                        message="If other apps use the current name to communicate with this app, make sure to update them as well to avoid problems."
                    />
                    <Input
                        style={{ marginTop: 15 }}
                        placeholder="app-name-here"
                        defaultValue={app.appName}
                        onChange={(e) => {
                            tempVal.newName = (e.target.value || '').trim()
                        }}
                    />
                </div>
            ),
            onOk() {
                const changed = app.appName !== tempVal.newName
                if (changed && tempVal.newName)
                    self.renameAppTo(tempVal.newName)
            },
        })
    }

    viewDescription() {
        const self = this
        const app = self.state.apiData!.appDefinition
        const tempVal = { tempDescription: app.description }
        Modal.confirm({
            title: 'App Description:',
            content: (
                <div>
                    <Input.TextArea
                        style={{ marginTop: 15 }}
                        placeholder="Use app description to take some notes for your app"
                        rows={12}
                        defaultValue={app.description}
                        onChange={(e) => {
                            tempVal.tempDescription = e.target.value
                        }}
                    />
                </div>
            ),
            onOk() {
                const changed = app.description !== tempVal.tempDescription
                app.description = tempVal.tempDescription
                if (changed) self.onUpdateConfigAndSave()
            },
        })
    }

    renameAppTo(newName: string) {
        const self = this
        const appDef = Utils.copyObject(self.state.apiData!.appDefinition)
        self.setState({ isLoading: true })
        this.apiManager
            .renameApp(appDef.appName!, newName)
            .then(function () {
                return self.reFetchData()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    onUpdateConfigAndSave() {
        const self = this
        const appDef = Utils.copyObject(self.state.apiData!.appDefinition)
        self.setState({ isLoading: true })
        this.apiManager
            .updateConfigAndSave(appDef.appName!, appDef)
            .then(function () {
                return self.reFetchData()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    render() {
        const self = this

        if (!self.state.apiData && self.state.isLoading) {
            return <CenteredSpinner />
        }

        if (!self.reRenderTriggered) {
            // crazy hack to make sure the Affix is showing (delete and save & update)
            self.reRenderTriggered = true
            setTimeout(function () {
                self.setState({ renderCounterForAffixBug: 1 })
            }, 50)
        }

        if (!self.state.apiData) {
            return <ErrorRetry />
        }

        const app = self.state.apiData.appDefinition

        return (
            <Flex style={{ width: '100%', justifyContent: 'center' }}>
                <Card 
                    style={{ width: '80%'}}
                    styles={{
                        body: {padding: 0},
                        header: {padding: '0.5em 1.5em'}
                    }}
                    title={
                        <Typography
                            style={{ fontSize: '2em', fontWeight: 600 }}
                        >
                            {app.appName}
                        </Typography>
                    }
                    extra={
                        <Flex
                            style={{
                                alignItems: 'center',
                                gap: 5,
                            }}
                        >
                            <ClickableLink
                                onLinkClicked={() => self.openRenameAppDialog()}
                            >
                                <Tooltip title="Rename App" placement="bottom">
                                    <Button icon={<EditOutlined />} />
                                </Tooltip>
                            </ClickableLink>

                            <ClickableLink
                                onLinkClicked={() => self.viewDescription()}
                            >
                                <Tooltip
                                    title="App description"
                                    placement="bottom"
                                >
                                    <Button icon={<ReadOutlined />} />
                                </Tooltip>
                            </ClickableLink>

                            <Button
                                style={{}}
                                type="primary"
                                onClick={() => self.onUpdateConfigAndSave()}
                            >
                                {self.props.isMobile ? (
                                    <SaveOutlined />
                                ) : (
                                    'Save & Restart'
                                )}
                            </Button>
                        </Flex>
                    }
                >
                    
                    {this.state.isLoading && (
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                            }}
                        >
                            <CenteredSpinner />
                        </div>
                    )}

                    <Layout
                        style={{
                            marginBottom: 0,
                            // height: '100%',
                            backgroundColor: '#00000000',
                        }}
                    >
                        <Sider
                            width={'20%'}
                            style={{backgroundColor: '#00000000'}}
                        >
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={[WEB_SETTINGS]}
                                onClick={(e) => {
                                    self.setState({ activeTabKey: e.key })
                                }}
                                style={{ height: '100%' }}
                                items={[
                                    {
                                        key: WEB_SETTINGS,
                                        label: (
                                            <span className="unselectable-span">
                                                HTTP Settings
                                            </span>
                                        ),
                                    },
                                    {
                                        key: APP_CONFIGS,
                                        label: (
                                            <span className="unselectable-span">
                                                App Configs
                                            </span>
                                        ),
                                    },
                                    {
                                        key: DEPLOYMENT,
                                        label: (
                                            <span className="unselectable-span">
                                                Deployment
                                            </span>
                                        ),
                                    },
                                    {
                                        key: LOGS,
                                        label: (
                                            <span className='unselectable-span'>
                                                Logs
                                            </span>
                                        )
                                    },
                                    {
                                        key: SETTINGS,
                                        label: (
                                            <span className="unselectable-span">
                                                Settings
                                            </span>
                                        ),
                                    },
                                ]}
                            />
                        </Sider>
                        <Content 
                            style={{ 
                                height: '80vh',
                                overflow: 'auto',
                                padding: '1em', 
                                minHeight: 280 
                            }}
                        >
                            {self.state.activeTabKey === WEB_SETTINGS && (
                                <HttpSettings
                                    isMobile={this.props.isMobile}
                                    setLoading={(value) =>
                                        this.setState({
                                            isLoading: value,
                                        })
                                    }
                                    reFetchData={() => this.reFetchData()}
                                    apiData={Utils.copyObject(
                                        this.state.apiData!
                                    )}
                                    apiManager={this.apiManager}
                                    updateApiData={(newData: any) =>
                                        this.setState({
                                            apiData: newData,
                                        })
                                    }
                                    onUpdateConfigAndSave={() =>
                                        self.onUpdateConfigAndSave()
                                    }
                                />
                            )}
                            {self.state.activeTabKey === APP_CONFIGS && (
                                <AppConfigs
                                    isMobile={this.props.isMobile}
                                    setLoading={(value) =>
                                        this.setState({
                                            isLoading: value,
                                        })
                                    }
                                    reFetchData={() => this.reFetchData()}
                                    apiData={Utils.copyObject(
                                        this.state.apiData!
                                    )}
                                    apiManager={this.apiManager}
                                    updateApiData={(newData: any) =>
                                        this.setState({
                                            apiData: newData,
                                        })
                                    }
                                    onUpdateConfigAndSave={() => {
                                        self.onUpdateConfigAndSave()
                                    }}
                                />
                            )}
                            {self.state.activeTabKey === DEPLOYMENT && (
                                <Deployment
                                    isMobile={this.props.isMobile}
                                    setLoading={(value) =>
                                        this.setState({
                                            isLoading: value,
                                        })
                                    }
                                    reFetchData={() => this.reFetchData()}
                                    apiData={Utils.copyObject(
                                        this.state.apiData!
                                    )}
                                    apiManager={this.apiManager}
                                    onUpdateConfigAndSave={() =>
                                        self.onUpdateConfigAndSave()
                                    }
                                    updateApiData={(newData: any) => {
                                        this.setState({
                                            apiData: newData,
                                        })
                                    }}
                                />
                            )}
                            {self.state.activeTabKey === LOGS && (
                                <AppLogsView
                                    appName={app.appName!}
                                    key={app.appName! + '-LogsView'}
                                />
                            )}
                            {self.state.activeTabKey === SETTINGS && (
                                <Settings
                                    isMobile={this.props.isMobile}
                                    setLoading={(value: boolean) =>
                                        this.setState({
                                            isLoading: value,
                                        })
                                    }
                                    reFetchData={() => this.reFetchData()}
                                    apiData={this.state.apiData!}
                                    apiManager={this.apiManager}
                                    onUpdateConfigAndSave={() =>
                                        self.onUpdateConfigAndSave()
                                    }
                                    updateApiData={(newData: any) => {
                                        this.setState({
                                            apiData: newData,
                                        })
                                    }}
                                />
                            )}
                        </Content>
                    </Layout>
                </Card>
            </Flex>
        )
    }

    componentDidMount() {
        this.reFetchData()
    }

    reFetchData() {
        const self = this
        self.setState({ isLoading: true })
        return this.apiManager
            .getAllApps()
            .then(function (data: any) {
                for (
                    let index = 0;
                    index < data.appDefinitions.length;
                    index++
                ) {
                    const element = data.appDefinitions[index]
                    if (element.appName === self.props.match.params.appName) {
                        self.setState({
                            isLoading: false,
                            apiData: {
                                appDefinition: element,
                                rootDomain: data.rootDomain,
                                captainSubDomain: data.captainSubDomain,
                                defaultNginxConfig: data.defaultNginxConfig,
                            },
                        })
                        return
                    }
                }

                // App Not Found!
                
                self.goBackToApps()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }
}

function mapStateToProps(state: any) {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect<IMobileComponent, any, any>(
    mapStateToProps,
    undefined
)(AppDetails)
