import { AreaChartOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { connect } from 'react-redux'
import { IMobileComponent } from '../../models/ContainerProps'
import Toaster from '../../utils/Toaster'
import Utils from '../../utils/Utils'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'
import NetDataDescription from './NetDataDescription'
import NetDataSettingsForm from './NetDataSettingsForm'

class NetDataInfo extends ApiComponent<
    {
        isMobile: boolean
    },
    { apiData: any; isLoading: boolean }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            apiData: undefined,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.refetchData()
    }

    refetchData() {
        const self = this
        self.setState({ isLoading: true, apiData: undefined })
        return this.apiManager
            .getNetDataInfo()
            .then(function (data) {
                self.setState({ apiData: data })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    toggleNetDataClicked(isActivated: boolean) {
        const netDataInfo = Utils.copyObject(this.state.apiData)
        netDataInfo.isEnabled = !!isActivated
        this.onUpdateNetDataClicked(netDataInfo)
    }

    onUpdateNetDataClicked(netDataInfo: any) {
        const self = this
        self.setState({ isLoading: true })
        return this.apiManager
            .updateNetDataInfo(netDataInfo)
            .then(function () {
                message.success(
                    netDataInfo.isEnabled
                        ? 'NetData is started and updated!'
                        : 'NetData has stopped!'
                )
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.refetchData()
            })
    }

    render() {
        const self = this

        if (this.state.isLoading) {
            return <CenteredSpinner />
        }

        if (!this.state.apiData) {
            return <ErrorRetry />
        }

        const netDataInfo = this.state.apiData

        return (
            <>
                <NetDataDescription />
                {!netDataInfo.isEnabled && (
                    <div style={{ marginTop: '20px', textAlign: 'right' }}>
                        <Button
                            onClick={() => self.toggleNetDataClicked(true)}
                            type="primary"
                        >
                            <span>
                                Start NetData Engine &nbsp;
                                <PoweroffOutlined />
                            </span>
                        </Button>
                    </div>
                )}

                {netDataInfo.isEnabled && (
                    <div style={{ marginTop: '20px' }}>
                        <div
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '20px',
                                flexWrap: self.props.isMobile
                                    ? 'wrap'
                                    : 'nowrap',
                            }}
                        >
                            <Button
                                block={self.props.isMobile}
                                onClick={() => self.toggleNetDataClicked(false)}
                                danger
                            >
                                <span>
                                    Turn NetData Off &nbsp;
                                    <PoweroffOutlined />
                                </span>
                            </Button>
                            <a
                                type="submit"
                                href={`//${netDataInfo.netDataUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    width: self.props.isMobile
                                        ? '100%'
                                        : 'auto',
                                }}
                            >
                                <Button
                                    block={self.props.isMobile}
                                    type="primary"
                                >
                                    <span>
                                        Open NetData &nbsp;
                                        <AreaChartOutlined />
                                    </span>
                                </Button>
                            </a>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <NetDataSettingsForm
                                updateModel={(netDataInfo) => {
                                    self.setState({ apiData: netDataInfo })
                                }}
                                netDataInfo={netDataInfo}
                            />
                        </div>
                        <div style={{ marginTop: '20px', textAlign: 'right' }}>
                            <Button
                                type="primary"
                                onClick={() =>
                                    self.onUpdateNetDataClicked(
                                        Utils.copyObject(self.state.apiData)
                                    )
                                }
                            >
                                Update NetData
                            </Button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect<IMobileComponent, any, any>(
    mapStateToProps,
    undefined
)(NetDataInfo)
